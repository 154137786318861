$('.js-tab-menu').click(function () {
  const index = $('.js-tab-menu').index(this)
  $('.js-tab-content').hide()
  $('.js-tab-content').eq(index).fadeIn()
  $('.js-tab-menu').removeClass('is-active')
  $(this).addClass('is-active');
})

const mediaQuery = window.matchMedia("(max-width:480px)")
mediaQuery.addListener(resizeFunction);

function resizeFunction() {
  if (mediaQuery.matches) {
    // 480px以下の時はタブを有効にする
    // console.log('below 480')
    $('.js-tab-content').eq(1).hide()
  } else {
    // 481px以上の時はタブコンテンツを両方出す
    $('.js-tab-content').show()
  }
}
