/*global $ */
$(document).ready(function () {

	"use strict";

	$('#js-megaMmenu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
	//Checks if li has sub (ul) and adds class for toggle icon - just an UI
	//	liにsub（ul）があるかどうかを確認し、トグルアイコンのクラスを追加します - 単なるUI

	$('#js-megaMmenu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
	//Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)
	//ドロップダウンがメガメニューではなく通常のドロップダウンとして表示されていない場合は、ドロップダウンメニューのli要素に異常レベル（ul）があるかどうかをチェックします。

	$("#js-megaMmenu > ul").before("<a href=\"#\" class=\"menu-mobile\"><div class=\"menu-mobile__trigger\"><div class=\"menu-mobile__btn\"><span></span><span></span><span></span></div></div></a>");

	//Adds menu-mobile class (for mobile toggle menu) before the normal menu
	//Mobile menu is hidden if width is more then 959px, but normal menu is displayed
	//Normal menu is hidden if width is below 959px, and jquery adds mobile menu
	//Done this way so it can be used with wordpress without any trouble

	//	通常のメニューの前にmenu-mobileクラス（モバイルトグルメニュー用）を追加します。
	//	幅が768ピクセルを超えるとモバイルメニューは非表示になりますが、通常のメニューは表示されます。
	//幅が768ピクセル未満の場合、通常のメニューは非表示になり、jqueryはモバイルメニューを追加します
	//問題なくワードプレスで使用できるように、このようにしてください。

	$("#js-megaMmenu > ul > li").hover(
		function (e) {
			if ($(window).width() > 768) {
				$(this).children("ul").fadeIn(150);
				e.preventDefault();
			}
		},
		function (e) {
			if ($(window).width() > 768) {
				$(this).children("ul").fadeOut(150);
				e.preventDefault();
			}
		}
	);
	//If width is more than 768px dropdowns are displayed on hover
	//幅が768pxを超える場合は、ホバーにドロップダウンが表示されます。

	//the following hides the menu when a click is registered outside
	//外部クリックでメニュー非表示にする
	$(document).on('click', function (e) {
		if ($(e.target).parents('#js-megaMmenu').length === 0)
			$("#js-megaMmenu > ul").removeClass('show-on-mobile');

	});

	$("#js-megaMmenu > ul > li").click(function () {
		//no more overlapping menus
		//hides other children menus when a list item with children menus is clicked
		//子メニューのあるリスト項目がクリックされたときに他の子メニューを非表示にする

		
		
		$(this).toggleClass("is-subMenuOpen").siblings('li').removeClass("is-subMenuOpen");
		var thisMenu = $(this).children("ul");
		var prevState = thisMenu.css('display');
		$("#js-megaMmenu > ul > li > ul").hide();

		if ($(window).width() < 769) {
			if (prevState !== 'block')
				thisMenu.show();
		}
	});
	//If width is less or equal to 769px dropdowns are displayed on click (thanks Aman Jain from stackoverflow)
	//	幅が769px以下の場合、クリック時にドロップダウンが表示されます
	$(".menu-mobile").click(function (e) {
		$("#js-header").toggleClass('fixed-on-mobile');
		//console.log("fixed");
		$("#js-megaMmenu > ul").toggleClass('show-on-mobile');
		$(".menu-mobile").toggleClass('is-active');
		e.preventDefault();
	});
	//when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story (thanks mwl from stackoverflow)
	//モバイルメニューをクリックすると、通常のメニューがリストとして表示されます。
});
