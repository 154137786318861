$('.js-tab > div > p').click(function () {
	$('.js-tab > div > p,.js-tab_content').removeClass('active');
	var tabClass = $(this).attr('class');
	$(this).addClass('active');

	$('.js-tab_content').each(function () {
		if ($(this).attr('class').indexOf(tabClass) != -1) {
			$(this).addClass('active').fadeIn('fast');
		} else {
			$(this).fadeOut('fast');
		}
	});
});

$('.js-acTrigger').on('click', function () {
	$(this).next('.js-acBody').slideToggle("fast");
	$(this).toggleClass('is-active');
});
$('.js-acClose').on('click', function () { //Trigger以外に別途閉じるボタンが必要な時
	$(this).closest('.js-acBody').slideToggle("fast");
	$(this).closest('.js-acBody').prev('.js-acTrigger').toggleClass('is-active');
});

//if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
//
//	$('.js-acTriggerSm').on('touchend', function () {
//		$(this).next('.js-acBodySm').slideToggle("fast");
//		$(this).toggleClass('is-active');
//	});
//}
